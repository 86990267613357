import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
import Action from '../components/Action';
export const _frontmatter = {
  "title": "Service Workies Ch2 — Reaction",
  "date": "2019-02-06",
  "promo": "serviceworkies",
  "description": "Reacting to the Service Worker Lifecycle",
  "color": "#82bdb9"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://serviceworkies.com"
      }}>{`Service Workies`}</a>{` chapter 2 is released and ready to play!`}</p>
    <p>{`In chapter 1 the game gently introduces you to the `}<em parentName="p">{`hardest`}</em>{` part about Service Worker — the lifecycle. When Service Workers get created, updated, and terminated. What `}<inlineCode parentName="p">{`waiting`}</inlineCode>{` vs `}<inlineCode parentName="p">{`active`}</inlineCode>{` is all about.`}</p>
    <p>{`In chapter two we build on this foundation and dive into `}<em parentName="p">{`reacting`}</em>{` to the Service Worker lifecycle.`}</p>
    <h2>{`Strong Ground`}</h2>
    <p>{`When building a PWA and learning Service Worker it can be super tempting to jump straight to the big wins of caching. But in the words of a wise Workie:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Strong walls can only be built on top of strong ground.`}<br parentName="p"></br>{`
`}{`— Lani`}</p>
    </blockquote>
    <p>{`We'll cover working with caches in excruciating detail in chapter 4. But first you need to know how to detect when a Service Worker has been successfully installed. Know when it moves on to its `}<inlineCode parentName="p">{`activated`}</inlineCode>{` state. Know how to access its state and detect when it changes.`}</p>
    <p>{`This chapter also introduces the `}<strong parentName="p">{`Service Worker Registration`}</strong>{`, and some neat things you can do with it like getting references to the various installed Service Workers.`}</p>
    <p>{`Lani also teaches you about the powerful but risky `}<inlineCode parentName="p">{`skipWaiting()`}</inlineCode>{` command.`}</p>
    <h2>{`Boss Fight!`}</h2>
    <p>{`Kolohe gets pretty fed up with the Crow. You're going to have to help him face down the beast using everything learned up to this point.`}</p>
    <p>{`I'm excited about something new I started doing in Chapter 2 — using an advanced `}<a parentName="p" {...{
        "href": "https://xstate.js.org/docs/guides/machines.html"
      }}>{`state machine`}</a>{` for the boss fight. There are multiple checkpoints where you'll probably fail and watch poor Kolohe 'splode. This level is intentionally hard. When you fail and can't remember the answer, go back and replay the level that teaches the thing you forgot. This will help drill the concepts into your brain. I'll write more about state machines later because I think they have sooo much potential for UI development.`}</p>
    <p>{`Good luck, have fun!`}</p>
    <Action link="https://serviceworkies.com" mdxType="Action">Play Now</Action>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      